import React from 'react';
import { Link } from "react-router-dom";
//import { useReducer } from 'react';

function Educatie({ page }) {
	window.scroll(0, 0)
	const [name, setName] = React.useState("");

	var canShow = localStorage.getItem('CanShow') || 0;
	function hashCode(str) {
		return str.split('').reduce((prevHash, currVal) =>
			(((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
	}

	function handleSubmit(event) {
		if (hashCode(name) === -1669545175) {
			localStorage.setItem('CanShow', 1);
		}
		else
		{
			localStorage.removeItem('CanShow');
		}
	}


	return (
		<div id="main" className="container">
			<div className="row">
				<div className="locatie">
					<h2>
						<i className="fa fa-graduation-cap fa-lg"></i>
						<Link to="/educatie">{ page.values.title.value }</Link>
					</h2>
				</div>
			</div>
			<div className="row uniform double">

					<section className="6u">
						<span className="image fit"><img src={process.env.PUBLIC_URL + '/images/Informatie-Hanzestad-Hasselt-Stadsmuur.jpg'} alt="" /></span>
					</section>
				{canShow ?
					<section className="6u">
						<p dangerouslySetInnerHTML={{ __html: page.values.text.value }}></p>
						<form onSubmit={handleSubmit}>
						<input type="hidden" value="LogOut" onChange={e => setName(e.target.value)} />
							<input type="submit" value="Log uit" />
						</form>
					</section>
					:
					<section className="6u">
						Geef hieronder de inlogcode voor toegang:
						<form onSubmit={handleSubmit}>
							<label>
								Code:
								<input type="text" value={name} onChange={e => setName(e.target.value)}/>
							</label>
							<input type="submit" value="Verstuur" />
						</form>
					</section>
				}

			</div>
		</div>
	);
}

export default Educatie;
