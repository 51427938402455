import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import ls from 'local-storage'

function Cookie() {
	const [showCookies, setShowCookies] = useState(true);

	const acceptCookies = () => {
		ls.set('cookies-accepted', true);
		setShowCookies(false);
	}

	const hideCookies = () => {
		console.log(ls)
		ls.set('cookies-accepted', false);
		window.sessionStorage.setItem('cookies-hidden', 'true');
		setShowCookies(false);
	}

	useEffect(() => {
		if (ls.get('cookies-accepted') === true || window.sessionStorage.getItem('cookies-hidden') === 'true') {
			setShowCookies(false)
		}
	}, [showCookies]);

	return showCookies ? (
		<div className="eupopup-container eupopup-container-top eupopup-color-default" style={{ display: 'block' }}>
			<div className="eupopup-head">
				Deze website gebruikt cookies.
			</div>
			<div className="eupopup-body">
				We gebruiken ze om u de beste ervaring te bieden. Als u onze website blijft
				gebruiken, gaan we ervan uit dat u alle cookies op deze website graag ontvangt.
			</div>
			<div className="eupopup-buttons">
				<Link to="#" className="eupopup-button eupopup-button_1" onClick={acceptCookies}>
					Accepteren
				</Link>
				<Link to="/cookies" target="_blank" className="eupopup-button eupopup-button_2">
					Lees&nbsp;meer
				</Link>
				<div className="clearfix"></div>
			</div>
			<Link to="#" className="eupopup-closebutton" onClick={hideCookies}>
				x
			</Link>
		</div>
	) : (<div></div>);
}

export default Cookie;
