import React from 'react';
import { Link } from "react-router-dom";

function share() {
	navigator.share({
		title: 'Bedevaartspad Hasselt',
		text: 'Ik loop het Bedevaartspad Hasselt, jij ook?',
		url: 'https://bedevaartspadhasselt.nl/',
	})
}

function Menu() {
	return (
		<div className="bottomnav">
			<Link to="/">
				<i className="fas fa-home fa-2x"></i>
			</Link>
			<Link to="/informatie">
				<i className="fas fa-info-circle fa-2x"></i>
			</Link>
			<Link to="/locaties">
				<i className="fas fa-list-ul fa-2x"></i>
			</Link>
			<Link to="/kaart">
				<i className="fas fa-map fa-2x"></i>
			</Link>
			<Link to="/educatie">
				<i className="fas fa-graduation-cap fa-2x"></i>
			</Link>
			<Link to="#" style={{ display: navigator.share ? 'initial' : 'none' }} onClick={share}>
				<i className="fas fa-share-alt fa-2x"></i>
			</Link>
		</div>
	);
}

export default Menu;
