import React from 'react';
import { Link } from "react-router-dom";

function Informatie({ page }) {
	window.scroll(0, 0)

	return (
		<div id="main" className="container">
			<div className="row">
				<div className="locatie">
					<h2>
						<i className="fas fa-info-circle fa-lg"></i>
						<Link to="/informatie">{ page.values.title.value }</Link>
					</h2>
				</div>
			</div>

			<div className="row uniform double">
				<section className="6u">
					<span className="image fit"><img src={process.env.PUBLIC_URL + '/images/Informatie-Hanzestad-Hasselt-Stadsmuur.jpg'} alt="" /></span>
				</section>

				<section className="6u">
					<p dangerouslySetInnerHTML={{ __html: page.values.text.value }}></p>
				</section>
			</div>
		</div>
	);
}

export default Informatie;
