import React from 'react';
import { Link } from 'react-scroll'

function Home({ page }) {
	window.scroll(0, 0)

	return (
		<div>
			<section id="homebanner">
				<span className="image fit logo"><img src={process.env.PUBLIC_URL + '/images/logo-Hanzestad_Hasselt.png'} alt="Bedevaartspad Hasselt" /></span>
				<div className="home-inner fade-in two">
					<h1>"Scan onderweg de</h1>
					<h1> QR-codes en bewandel </h1>
					<h1>samen met mij het</h1>
					<h1> Bedevaartspad"</h1>
					<h2><Link to="faq" smooth={true} style={{ cursor: 'pointer' }}><i className="fas fa-arrow-circle-down fa-2x"></i></Link></h2>
				</div>
			</section>

			<div id="main" className="container">

				<div className="row uniform double">
					<section className="6u">
						<span className="image fit "><img src={process.env.PUBLIC_URL + '/images/logoBedevaartspadHasselt.png'} alt="Bedevaartspad Hasselt" /></span>
						<span className="image fit none"><img src={process.env.PUBLIC_URL + '/images/5D4L7903.png'} alt="Bedevaartspad Hasselt" /></span>
					</section>

					<section className="6u">
						<p dangerouslySetInnerHTML={{ __html: page.values.text.value }}></p>
					</section>
				</div>
			</div>
		</div>
		/*<section id="banner">
			<div className="bannerbox">
				<span className="image fit">
					<img src={process.env.PUBLIC_URL + '/images/logoBedevaartspadHasselt.png'} alt="Bedevaartspad Hasselt" />
				</span>
				<p dangerouslySetInnerHTML={{ __html: page.values.text.value }}></p>
			</div>
		</section>*/
	);
}

export default Home;
