import React from 'react';
import { Link } from "react-router-dom";

function Locaties({ locations }) {
	window.scroll(0, 0)

	return (
		<div id="main" className="container">
			<div className="row">
				<div className="locatie"><h2><i className="fas fa-list-ul fa-lg"></i>
					<Link to="/locaties">Locaties </Link>
				</h2></div>
			</div>
			<div className="row uniform double">
				<section className="6u">
					<span className="image fit "><img src={process.env.PUBLIC_URL + '/images/logoBedevaartspadHasselt.png'} alt="" /></span>
					<span className="image fit none"><img src={process.env.PUBLIC_URL + '/images/5D4L7903.png'} alt="" /></span>
				</section>
				<section className="6u">
					{
						locations.filter((l) => l.values.prefix !== undefined).map((l, i) => {
							return (
								<Link to={"/locatie/" + l.urlName} key={l.id}>
									<div className={"locationbanner fade-in fade-in-" + i}>
										<span className="image fit">
											{
												l.values.small_image !== undefined && JSON.parse(l.values.small_image.value).length > 0 ?
													<img src={process.env.PUBLIC_URL + '/images/uploads/' + JSON.parse(l.values.small_image.value)[0]} alt="" /> : 
													<img src={process.env.PUBLIC_URL + '/images/Location1.jpg' } alt="" />
											}
										</span>
										<div className="locationinner">
											<h1>{l.values.prefix.value + '. ' + l.values.title.value}</h1>
										</div>
									</div>
								</Link>
							);
						})
					}
				</section>
			</div>
		</div>
	);
}

export default Locaties;
