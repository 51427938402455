import React from "react";
import { Link } from "react-router-dom";
import MapComponent from "../components/Map";

function Map() {
	return (
		<div id="main" className="container">
			<div className="row">
				<div className="locatie"><h2><i className="fas fa-map fa-lg"></i> <Link to="#">Bedevaartspad hasselt</Link></h2></div>
			</div>

			<div className="row uniform double">
				<section className="12u">
					<MapComponent />
				</section>
				<section className="6u">
					<div className="inner">
					</div>
				</section>
			</div>
		</div>
	);
}

export default Map;
