import React, { useState } from 'react';
import { Link } from "react-router-dom";

function QrPage({ page }) {
	const [imageShown, setImageShown] = useState(null);
	const [imageDimension, setImageDimension] = useState([-1,-1]);
	const [screenDimension, setScreenDimension] = useState([window.innerWidth, window.innerHeight ]);

	window.scroll(0, 0)

	function handleResize() {
		setScreenDimension([window.innerWidth, window.innerHeight]);
	}

	window.addEventListener('resize', handleResize);

	function showImage(e) {
		if (!e.target.classList.contains('editor')) return;

		setImageShown(e.target.src);
		setImageDimension([e.target.offsetWidth, e.target.offsetHeight]);
		
		e.preventDefault();
	}

	function hideImage(e) {
		setImageShown(null);
		setImageDimension([-1, -1]);
	}

	function needsRotate() {
		let i = imageDimension[0] / imageDimension[1];
		let s = screenDimension[0] / screenDimension[1];

		return (i < 1 && s > 1) || (i > 1 && s < 1)
	}

	return (
		<div>
			<div id="main" className="container">
				<div className="row">
					<div className="locatie">
						<h2>
							<i className="fas fa-qrcode fa-lg"></i>
							<Link to={"/page/" + page.urlName}>{ page.values.title.value }</Link>
						</h2>
					</div>
				</div>

				<div className="row uniform double">
					<section className="12u">
						<p dangerouslySetInnerHTML={{ __html: page.values.text.value }} onClick={showImage}></p>
					</section>
				</div>
			</div>

			{
				imageShown == null ? (<div></div>) : (
					<div onClick={hideImage} style={{
						background: 'rgba(0,0,0,0.85)',
						position: 'fixed',
						width: '100vw',
						height: '100vh',
						bottom: 0,
						left: 0,
						margin: 'auto',
						overflow: 'auto',
						right: 0,
						top: 0,
						OObjectFit: 'contain',
						objectFit: 'contain',
						zIndex: 2000
						}}>
						<img src={imageShown} style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							margin: 'auto',
							overflow: 'auto',
							right: 0,
							top: 0,
							OObjectFit: 'contain',
							objectFit: 'contain',
							zIndex: 2001,
							inset: -10000,

							maxWidth: needsRotate() ? '100vh' : '100vw',
							maxHeight: needsRotate() ? '100vw' : '100vh',
							transform: needsRotate() ? 'rotate(90deg)' : '',
						}} alt="" />
						{
							needsRotate() ? (
								<span className="fa-stack" style={{ zIndex: 2001, color: 'rgb(200,200,200)', position: 'fixed', left: 10, top: 10 }}>
									<i className="fas fa-mobile-alt fa-stack-2x" style={{ left: '-16%', color: 'rgba(200,200,200, 0.5)'}}></i>
									<i className="fas fa-mobile-alt fa-stack-2x fa-rotate-270" style={{ top: '-21%' }}></i>
									<i className="fas fa-reply fa-stack-1x fa-rotate-90" style={{left: '30%', top: '40%', fontSize: '.75em'}}></i>
								</span>) : ''
						}
					</div>
				)
			}
		</div>
	);
}

export default QrPage;
