import React from 'react';
import { Link } from "react-router-dom";

function Page({ page }) {
	window.scroll(0, 0)

	return (
		<div id="main" className="container">
			<div className="row">
				<div className="locatie">
					<h2>
						<i className="fas fa-scroll fa-lg"></i>
						<Link to={"/page/" + page.urlName}>{ page.values.title.value }</Link>
					</h2>
				</div>
			</div>

			<div className="row uniform double">
				<section className="12u">
					<p dangerouslySetInnerHTML={{ __html: page.values.text.value }}></p>
				</section>
			</div>
		</div>
	);
}

export default Page;
