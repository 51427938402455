import React, { useEffect, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";

const Pintype = {
	Route: "Route",
	QR: "QR",
	Film: "Film",
	GWR: "GWR",
};

function MapComponent({ centerMarkerUrl }) {
	const [map, setMap] = useState(null);
	const [centerUrl, setCenterUrl] = useState(centerMarkerUrl);
	const mapContainer = useRef(null);

	const styles = centerMarkerUrl ? {
		width: "100%",
		height: "400px"
	} : {
		width: "100%",
		height: "calc(70vh)"
	};

	useEffect(() => {
		const markers = [

			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.089365, lat: 52.59068 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.088903, lat: 52.590683 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.088171, lat: 52.590546 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.089928, lat: 52.590528 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.090110, lat: 52.589919 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.091604, lat: 52.589554 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.091792, lat: 52.589476 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.091726, lat: 52.590999 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.091171, lat: 52.591630 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.090581, lat: 52.591980 },
			},
			{
				type: Pintype.GWR,
				url: '/gedichtenwandelroute',
				icon: 'pinsGWR.png',
				coordinates: { lng: 6.091689, lat: 52.591538 },
			},
			{
				type: Pintype.Route,
				prefix: '1',
				title: 'Oude Stadhuis',
				url: '/locatie/oude-stadhuis',
				film: 'https://youtu.be/RvicOKzaYow',
				icon: 'pins1.png',
				coordinates: { lng: 6.08957, lat: 52.59080 },
			},
			{
				type: Pintype.Route,
				prefix: '2',
				title: 'Veerpoort',
				url: '/locatie/veerpoort',
				icon: 'pins2.png',
				coordinates: { lng: 6.088, lat: 52.59050 },
			},
			{
				type: Pintype.Route,
				prefix: '3',
				title: 'Boekdrukkerij Barmentlo',
				url: '/locatie/boekdrukkerij-barmentlo',
				film: 'https://youtu.be/7UqXtocpq58',
				icon: 'pins3.png',
				coordinates: { lng: 6.0889, lat: 52.59137 },
			},
			{
				type: Pintype.Route,
				prefix: '4',
				title: 'Heilige Geest Gasthuiskapel',
				url: '/locatie/heilige-geest-gasthuiskapel',
				film: 'https://youtu.be/k702an9RLws',
				icon: 'pins4.png',
				coordinates: { lng: 6.08896, lat: 52.59186 },
			},
			{
				type: Pintype.Route,
				prefix: '5',
				title: 'Mariaklooster',
				url: '/locatie/mariaklooster',
				icon: 'pins5.png',
				coordinates: { lng: 6.09129, lat: 52.59158 },
			},
			{
				type: Pintype.Route,
				prefix: '5',
				title: 'Mariaklooster',
				url: '/locatie/mariaklooster',
				icon: 'pins5.png',
				coordinates: { lng: 6.091534, lat: 52.591654 },
			},
			{
				type: Pintype.Route,
				prefix: '6',
				title: 'Heilige Stede',
				url: '/locatie/heilige-stede',
				film: 'https://youtu.be/dsiafL9gpXY',
				icon: 'pins6.png',
				coordinates: { lng: 6.09296, lat: 52.58907 },
			},
			{
				type: Pintype.Route,
				prefix: '7',
				title: 'Grote Kerk',
				url: '/locatie/grote-kerk',
				film: 'https://youtu.be/hJ4X2lyFobQ',
				icon: 'pins7.png',
				coordinates: { lng: 6.0898, lat: 52.59025 },
			},
			{
				type: Pintype.Route,
				prefix: '+1',
				title: 'Zwartewaterklooster',
				url: '/locatie/zwartewaterklooster',
				icon: 'pins8.png',
				coordinates: { lng: 6.10548, lat: 52.61891 },
			},
			{
				type: Pintype.Film,
				title: 'Kapel RK Begraafplaats',
				film: 'https://youtu.be/2b37FsAWTfw',
				icon: 'pinsFILM.png',
				coordinates: { lng: 6.09446, lat: 52.59145 },
			},
			{
				type: Pintype.QR,
				prefix: 'QR',
				title: 'Nieuwstraat',
				url: '/springer_nieuwstraat',
				icon: 'pinsQR.png',
				coordinates: { lng: 6.09149, lat: 52.59110 },
			},
			{
				type: Pintype.QR,
				prefix: 'QR',
				title: 'Gasthuisstraat',
				url: '/springer_gasthuisstraat',
				icon: 'pinsQR.png',
				coordinates: { lng: 6.09249, lat: 52.59125 },
			},
			{
				type: Pintype.QR,
				prefix: 'QR',
				title: 'Stadsgracht',
				url: '/springer_stadsgracht',
				icon: 'pinsQR.png',
				coordinates: { lng: 6.09256, lat: 52.59027 },
			}
		];
		const routeCoordinates = [
			[6.08957, 52.59080], //1
			[6.089554, 52.590854],
			[6.089245, 52.590806],
			[6.088162, 52.590549],
			[6.088, 52.59050], //2
			[6.088127, 52.590559],
			[6.087926, 52.590859],
			[6.087856, 52.591309],
			[6.087966, 52.591302],
			[6.0889, 52.59137], //3
			[6.088942, 52.591380],
			[6.088794, 52.59186],
			[6.08896, 52.59187],
			[6.08896, 52.59186], //4
			[6.08896, 52.59187],
			[6.089617, 52.591913],
			[6.090891, 52.591949],
			[6.09125, 52.591575],
			[6.09129, 52.59158], //5
			[6.09125, 52.591575],
			[6.091675, 52.591112],
			[6.09194, 52.59115],
			[6.091534, 52.591654], //5
			[6.09194, 52.59115],
			[6.091976, 52.591128],
			[6.092427, 52.590636],
			[6.092491, 52.590375],
			[6.092480, 52.590217],
			[6.092432, 52.590080],
			[6.093870, 52.58977],
			[6.093803, 52.589473],
			[6.093527, 52.588912],
			[6.093329, 52.588932],
			[6.09296, 52.58907], //6
			[6.092964, 52.589055],
			[6.091915, 52.589444],
			[6.091677, 52.589511],
			[6.091328, 52.589596],
			[6.091090, 52.589356],
			[6.090859, 52.589400],
			[6.090446, 52.589562],
			[6.08996, 52.58973],
			[6.08999, 52.58984],
			[6.09011, 52.589955],
			[6.089895, 52.590228],
			[6.08985, 52.59025],
			[6.0898, 52.59025], //7
		];
		const centerMarker = markers.filter((m) => m.url !== undefined && m.url === centerMarkerUrl)[0]

		if (centerUrl !== centerMarkerUrl) {
			setCenterUrl(centerMarkerUrl);
			map.setCenter(centerMarker.coordinates);
		}
		
		mapboxgl.accessToken = 'pk.eyJ1Ijoiam9yZHl2YW5kZXJwb2VsIiwiYSI6ImNqdXZvOWZkejAzaWY0NXFoZGdrZDRkNHYifQ.K7cBpk3CpiJ04otvYKWxLw';
		const initializeMap = ({ setMap, mapContainer }) => {
			const map = new mapboxgl.Map({
				container: mapContainer.current,
				style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
				center: (centerMarker ? centerMarker.coordinates : { lng: 6.092, lat: 52.5907 }),
				zoom: (centerMarker ? 17 : 16)
			});

			map.on("load", () => {
				setMap(map);
				map.resize();

				map.addSource('route', {
					'type': 'geojson',
					'data': {
						'type': 'Feature',
						'properties': {},
						'geometry': {
							'type': 'LineString',
							'coordinates': routeCoordinates
						}
					}
				});
				map.addLayer({
					'id': 'route',
					'type': 'line',
					'source': 'route',
					'layout': {
						'line-join': 'round',
						'line-cap': 'round'
					},
					'paint': {
						'line-color': '#08F',
						'line-width': 4
					}
				});
			});

			var nav = new mapboxgl.NavigationControl();
			map.addControl(nav, 'bottom-right');

			markers.forEach((marker) => {
				var el = document.createElement('div');
				el.className = 'marker';
				el.style.backgroundImage = 'url(' + process.env.PUBLIC_URL + '/images/pins/' + marker.icon + ')';
				el.style.backgroundSize = 'cover';
				el.style.width = '32px';
				el.style.height = '32px';

				let popupTitle = '';
				if (marker.type === Pintype.Route) {
					popupTitle = '<h3>' + marker.prefix + '.<br/>' + marker.title + '</h3>';
				} else if (marker.type === Pintype.QR) {
					popupTitle = '<h3><i class="fas fa-qrcode"></i>&nbsp;&nbsp;' + marker.title + '</h3>';
				}

				let popupLinks = [];
				if (marker.url !== undefined) {
					popupLinks.push('<a href="' + marker.url + '">Lees meer</a>');
				}
				if (marker.film !== undefined) {
					popupLinks.push('<a href="' + marker.film + '" target="_BLANK">Bekijk film</a>');
				}
				popupLinks.push('<a href="https://www.google.nl/maps/place/' + marker.coordinates.lat + 'N,' + marker.coordinates.lng + 'E" target="_BLANK">Navigeer</a>');

				let popupHTML = popupTitle + popupLinks.join('&nbsp;|&nbsp;');
				let popup = new mapboxgl.Popup({ maxWidth: '270px', offset: 25 }).setHTML(popupHTML);
				new mapboxgl.Marker(el).setLngLat(marker.coordinates).setPopup(popup).addTo(map);
			});

			var geolocate = new mapboxgl.GeolocateControl({
				trackUserLocation: true,
				showAccuracyCircle: true,
				showUserLocation: true
			});
			map.addControl(geolocate);

			if (!centerMarker) {
				let boundBox = [{ lng: null, lat: null }, { lng: null, lat: null }];

				routeCoordinates.forEach((routePoint) => {
					boundBox[0].lng = boundBox[0].lng ? Math.min(boundBox[0].lng, routePoint[0]) : routePoint[0];
					boundBox[0].lat = boundBox[0].lat ? Math.min(boundBox[0].lat, routePoint[1]) : routePoint[1];
					boundBox[1].lng = boundBox[1].lng ? Math.max(boundBox[1].lng, routePoint[0]) : routePoint[0];
					boundBox[1].lat = boundBox[1].lat ? Math.max(boundBox[1].lat, routePoint[1]) : routePoint[1];
				});

				map.fitBounds(boundBox, {
					padding: { top: 32, bottom: 32, left: 32, right: 32 }
				});
			}
		};

		if (!map) initializeMap({ setMap, mapContainer });
	}, [map, centerUrl, centerMarkerUrl]);

	return (
		<div ref={el => (mapContainer.current = el)} style={styles} />
	);
}

export default MapComponent;
