import React from 'react';
import { Link, useParams } from "react-router-dom";
import MapComponent from "../components/Map";

function Locatie({ locations }) {
	let { id } = useParams();
	
	let location = locations.filter((v) => {
		return v.urlName === id;
	})[0];

	window.scroll(0,0)

	return (
		<div>
			<section id="banner">
				<div className="banner">
					<span className="image fit fade-in zero">
						{
							JSON.parse(location.values.images.value).length > 0 ?
								<img src={process.env.PUBLIC_URL + '/images/uploads/' + JSON.parse(location.values.images.value)[0]} alt="" /> :
								<img src={process.env.PUBLIC_URL + '/images/Locatie_Hoogstraat.jpg'} alt="" />
						}
					</span>
					<div className="inner fade-in three">
						<h1>{location.values.title.value.toUpperCase()}</h1>
					</div>
				</div>
			</section>

			<div id="main" className="container">
				<div className="row uniform double">
					<section className="6u">
						<span className="image fit none"><img src={process.env.PUBLIC_URL + '/images/5D4L7903.png'} alt="" /></span>
					</section>
					<section className="6u">
						<div className="locatie">
							<h2>
								<i className="fas fa-map-marker-alt fa-lg"></i>
								<Link to="#">
									{
										location.values.address === undefined ? '' : location.values.address.value
									}
								</Link>
							</h2>
						</div>

						<p dangerouslySetInnerHTML={{ __html: location.values.text.value }}></p>

						{/*<ul className="actions">
							<li><a href="#pagina2" className="button">EXTRA TIPS</a></li>
							<li><Link to="#" className="button alt">EXTRA INFORMATIE</Link></li>
						</ul>*/}

						<div className="box">
							<div className="pagination">
								{
									locations.filter(l => {
										return l.values.prefix !== undefined;
									}).map((l) => {
										return (
											l.urlName === id ? <Link className="active" to={"/locatie/" + l.urlName} key={l.id}>{l.values.prefix.value}</Link> : <Link to={"/locatie/" + l.urlName} key={l.id}>{l.values.prefix.value}</Link>
										)
									})
								}
							</div>
						</div>

						<MapComponent centerMarkerUrl={"/locatie/" + location.urlName} />
					</section>
				</div>
			</div>
		</div>
	);
}

export default Locatie;
