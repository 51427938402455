import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import ls from 'local-storage'
import jquery from 'jquery';
import ScriptTag from 'react-script-tag';

import Menu from './components/Menu';
import Cookie from './components/Cookie';
import Home from './pages/Home';
import Loader from './pages/Loader';
import Locaties from './pages/Locaties';
import Locatie from './pages/Locatie';
import Informatie from './pages/Informatie';
import Educatie from './pages/Educatie';
import Map from './pages/Map';
import Page from './pages/Page';
import QrPage from './pages/QrPage';

window.$ = window.jQuery = jquery;

function App() {
	const numScripts = 4;
	const [appData, setAppData] = useState();
	const [scriptsLoaded, setScriptsLoaded] = useState(0);

	const scriptLoaded = () => {
		setScriptsLoaded(loaded => loaded + 1);
	};

	useEffect(() => {
		if (!appData) {
			getAppData();
		}
	}, [appData]);

	const getAppData = async () => {
		await fetch('https://bedevaartspadhasselt.nl/api/load')
			.then(res => res.json())
			.then(
				(result) => {
					setAppData(result);
					ls.set('pages', result.pages);
					ls.set('locations', result.locations);
				},
				(error) => {
					console.error('An error occured while retrieving appdata: ');
					console.error(error);

					console.log('Attempting load from localstorage');
					let p = ls.get('pages');
					let l = ls.get('locations');
					if (l && p) {
						setAppData(p);
						setAppData(l);
						console.log('Loaded from localstorage');
					} else {
						console.warn('Data from localstorage not available');
					}
				}
			)
	}

	const getPage = (pages, urlName) => {
		return pages.filter((p) => p.urlName === urlName)[0]
	}

	const scriptTags = (
		<div>
			{ scriptsLoaded >= 0 && <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + '/js/jquery.min.js'} onLoad={scriptLoaded} /> }
			{ scriptsLoaded >= 1 && <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + '/js/skel.min.js'} onLoad={scriptLoaded} /> }
			{ scriptsLoaded >= 2 && <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + '/js/skel-layers.min.js'} onLoad={scriptLoaded} /> }
			{ scriptsLoaded >= 3 && <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + '/js/init.js'} onLoad={scriptLoaded} /> }
		</div>
	);

	const baseApp = !!appData && scriptsLoaded >= numScripts ? (
		<div className="App">
			<Router>
				<div>
					<Switch>
						<Route path="/home">
							<Home page={getPage(appData.pages, 'home')} />
						</Route>
						<Route path="/locatie/:id/:page" render={({ match }) => {
							let page = getPage(appData.pages, match.params.id + '-' + match.params.page);
							if (page) {
								return <Page page={page} />;
							} else {
								return <Locatie locations={appData.locations} />;
							}
						}} />
						<Route path="/locatie/:id">
							<Locatie locations={appData.locations} />
						</Route>
						<Route path="/locaties">
							<Locaties locations={appData.locations} />
						</Route>
						<Route path="/informatie">
							<Informatie page={getPage(appData.pages, 'informatie')} />
						</Route>
						<Route path="/educatie">
							<Educatie page={getPage(appData.pages, 'educatie')} />
						</Route>
						<Route path="/kaart">
							<Map />
						</Route>

						<Route path="/springer_nieuwstraat">
							<QrPage page={getPage(appData.pages, 'springer-nieuwstraat')} />
						</Route>
						<Route path="/springer_gasthuisstraat">
							<QrPage page={getPage(appData.pages, 'springer-gasthuisstraat')} />
						</Route>
						<Route path="/springer_stadsgracht">
							<QrPage page={getPage(appData.pages, 'springer-stadsgracht')} />
						</Route>

						
						<Route path="/gedichtenwandelroute">
							<Page page={getPage(appData.pages, 'gedichtenwandelroute')} />
						</Route>
						<Route path="/page/:url" render={({ match }) => {
							let page = getPage(appData.pages, match.params.url);
							if (page) {
								return <Page page={page} />;
							} else {
								return <Home page={getPage(appData.pages, 'home')} />;
							}
						}} />

						<Route path="/cookies">
							<Page page={getPage(appData.pages, 'cookies')} />
						</Route>

						<Route path="/">
							<Home page={getPage(appData.pages, 'home')} />
						</Route>
					</Switch>

					<Menu />
					<Cookie />
				</div>
			</Router>
		</div>
	) : (
		<div className="App">
			<Loader stateContent={!!appData} stateScripts={scriptsLoaded >= numScripts} />
		</div>
	);

	return (
		<div>
			{ baseApp }
			{ scriptTags }
		</div>
	);
}

export const history = createBrowserHistory({
	basename: process.env.PUBLIC_URL
});

export default App;
