import React from 'react';

function Loader({ stateContent, stateScripts }) {
	return (
		<div className='pageloader'>
			<div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
			<h3>
				{!stateContent && !stateScripts ? 'Aan het laden' : (!stateScripts ? 'Scripts laden' : 'Content ophalen')}...
			</h3>
		</div>
	);
}

export default Loader;
